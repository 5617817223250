<template>
	<div class="page-layout" v-loading="loading">
		<gc-header headerData="waterMeterMonitor">
			<template #ops>
				<el-button type="primary" @click="handleExport">导 出</el-button>
			</template>
		</gc-header>
		<div class="content">
			<div class="left">
				<GcRegionTree v-model="form.waterRegionCode" :initSelect="false" />
			</div>
			<div class="run-main">
				<div class="serach">
					<gc-custom-search keyWord="用户号" width="300px" :search.sync="form.userNo"></gc-custom-search>
					<gc-custom-search keyWord="客户名称" width="300px" :search.sync="form.userName"></gc-custom-search>
					<gc-custom-search keyWord="设备编号" width="300px" :search.sync="form.deviceNo"></gc-custom-search>
					<gc-custom-search
						keyWord="设备类型"
						width="300px"
						type="select"
						:search.sync="form.deviceTypeId"
						:searchOption="options.deviceTypeId"
						:search-option-key="{
							label: 'deviceTypeName',
							value: 'deviceTypeId',
						}"
						:needAllForSearch="true"
					></gc-custom-search>
					<gc-custom-search
						keyWord="厂商"
						width="300px"
						type="select"
						:search.sync="form.manufacturerId"
						:searchOption="options.manufacturerId"
						:needAllForSearch="true"
					></gc-custom-search>
					<gc-custom-search keyWord="地址" width="300px" :search.sync="form.address"></gc-custom-search>
					<gc-custom-search keyWord="手机号" width="300px" :search.sync="form.phone"></gc-custom-search>
					<gc-custom-search
						keyWord="阀门状态"
						width="300px"
						type="select"
						:search.sync="form.valveStatus"
						:searchOption="options.valveStatus"
						:needAllForSearch="true"
					></gc-custom-search>
					<gc-custom-search
						keyWord="未上传天数"
						type="input-number"
						width="300px"
						keyWordWidth="86px"
						:search.sync="form.notUpLoadDay"
					></gc-custom-search>
					<div class="serach-ops">
						<el-button
							type="primary"
							@click="getList(1, apiWaterMetrologicalMonitorList, null)"
							v-click-blur
						>
							查 询
						</el-button>
						<el-button @click="reset(apiWaterMetrologicalMonitorList, null)" v-click-blur>重 置</el-button>
					</div>
					<gc-column-setting
						storageKey="localcolumn-water-meter-monitor"
						:columns="columns"
						@update-column="data => (columns = data)"
					></gc-column-setting>
				</div>
				<div class="table-show">
					<gc-table
						:columns="columnList"
						:table-data="tableData"
						:border="true"
						:showPage="true"
						:total="page.total"
						:page-size="page.size"
						:currentPage="page.current"
						@current-page-change="
							currentPage => {
								currentPageChange(currentPage, apiWaterMetrologicalMonitorList, null)
							}
						"
						@size-change="
							size => {
								page.size = size
								currentPageChange(1, apiWaterMetrologicalMonitorList, null)
							}
						"
					></gc-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { apiWaterMetrologicalMonitorList } from '@/apis/alarm.api.js'
import common from '../mixins/common.js'
import { exportReport, apiGetManufacturerList } from '@/apis/statisticAnalysis.api'
import { isBlank } from '@/utils/validate.js'
import { mapActions } from 'vuex'

export default {
	name: 'waterMeterMonitor',
	mixins: [common],
	data() {
		return {
			form: {
				waterRegionCode: null,
				userNo: null,
				userName: null,
				deviceNo: null,
				deviceTypeId: null,
				manufacturerId: null,
				address: null,
				phone: null,
				valveStatus: null,
				notUpLoadDay: undefined,
			},
			columns: [
				{
					key: 'userNo',
					name: '用户号',
					fixed: 'left',
					dragDisable: true,
					disabledSelect: true,
				},
				{
					key: 'userName',
					name: '客户名称',
				},
				{
					key: 'deviceNo',
					name: '设备编号',
				},
				{
					key: 'address',
					name: '地址',
				},
				{
					key: 'phone',
					name: '手机号',
				},
				{
					key: 'waterRegionName',
					name: '所属区域',
				},
				{
					key: 'caliber',
					name: '口径',
				},
				{
					key: 'rsrp',
					name: '信号强度',
				},
				{
					key: 'snr',
					name: '信噪比',
				},
				{
					key: 'voltage',
					name: '电池电压（V）',
				},
				{
					key: 'deviceStatusName',
					name: '设备状态',
				},
				{
					key: 'valveStatus',
					name: '阀门状态',
				},
				{
					key: 'curPositiveAccumulation',
					name: '正向流量（m³）',
				},
				{
					key: 'curNegativeAccumulation',
					name: '反向流量（m³）',
				},
				{
					key: 'curTotalFlow',
					name: '累积流量（m³）',
				},
				{
					key: 'temperature',
					name: '温度（℃）',
				},
				{
					key: 'manufacturer',
					name: '厂商',
					tooltip: true,
				},
				{
					key: 'deviceName',
					name: '协议名称',
					tooltip: true,
				},
				{
					key: 'pressure',
					name: '压力（Kpa）',
				},
				{
					key: 'sendSuccessRate',
					name: '通讯成功率',
				},
				{
					key: 'dataTime',
					name: '数据日期',
					minWidth: 180,
				},
			],
			options: {
				valveStatus: [
					{ label: '开启', value: 1 },
					{ label: '关闭', value: 0 },
				],
			},
		}
	},
	computed: {
		userInfo() {
			return this.$store.getters.userInfo
		},
		columnList() {
			return this.columns.filter(o => o.selected)
		},
	},
	watch: {
		'form.waterRegionCode': {
			handler(val) {
				if (val) {
					this.getList(1, apiWaterMetrologicalMonitorList, null)
				}
			},
		},
	},

	mounted() {
		this.getManufacturerList()
		this.getDeviceTypes().then(res => {
			this.$set(this.options, 'deviceTypeId', res)
		})
		this.getList(1, apiWaterMetrologicalMonitorList, null)
		const { tenantType, defaultWaterRegionCode } = this.$store.state.user.userInfo
		tenantType == 1 && this.$set(this.form, 'waterRegionCode', defaultWaterRegionCode)
	},

	methods: {
		...mapActions({
			getDeviceTypes: 'common/getAllDeviceTypeList',
		}),
		getManufacturerList() {
			apiGetManufacturerList({
				current: 1,
				size: 9999,
				tenantId: this.$store.getters.userInfo.tenantId || undefined,
			}).then(({ records = [] }) => {
				let val = records.map(item => {
					return {
						value: item.manufacturerId,
						label: item.manufacturerName,
					}
				})
				this.$set(this.options, 'manufacturerId', val)
			})
		},

		apiWaterMetrologicalMonitorList,
		handleExport() {
			if (!this.tableData.length) {
				this.$message.warning('暂无表格数据')
				return
			}
			this.$toast('下载中，请稍后...')
			let params = {}
			for (let key in this.form) {
				if (!isBlank(this.form[key])) {
					params[key] = this.form[key]
				}
			}
			const data = {
				method: 'post',
				url: '/monitor/report/water-measurement-data/export/excel',
				fileName: '计量监控.xlsx',
				data: {
					...params,
					tenantType: this.$store.getters.userInfo.tenantType || '0',
				},
			}
			exportReport(data, 'excel', '', this.$toast)
		},
	},
}
</script>
<style lang="scss" scoped>
@import '../runLayout.scss';
.content {
	flex: 1;
	height: 0;
	display: flex;
	.left {
		width: 300px;
		height: 100%;
		padding: 20px 12px;
		border-right: 1px solid #f1f1f1;
	}
	::v-deep .run-main {
		height: 100%;
		width: 0;
	}
}
</style>
